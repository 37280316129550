import Axios from '../lib/axios';

const ImportSalesBonus = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/sales-bonus/upload', formData);
};
const ImportCompRollUp = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/sales-bonus/upload/rollup', formData);
};

const SendBonusEmails = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/sales-bonus/emails', formData);
};

const GetSalesComp = (salesCompId?: string) => {
  return Axios.get(`${process.env.REACT_APP_SERVER_ROOT || ''}/sales-bonus/sales-comp/${salesCompId || ''}`);
};

const GetSalesCompDetail = (salesCompId: string) => {
  return Axios.get(`${process.env.REACT_APP_SERVER_ROOT || ''}/sales-bonus/${salesCompId}/details`, {
    params: { salesCompId },
  });
};

const DeleteSalesComp = (salesCompId: string) => {
  return Axios.delete(process.env.REACT_APP_SERVER_ROOT + '/sales-bonus/sales-comp/' + salesCompId);
};

const GetSalesRollUp = (salesCompId?: string) => {
  return Axios.get(`${process.env.REACT_APP_SERVER_ROOT || ''}/sales-bonus/rollup/${salesCompId || ''}`);
};

const GetTemplate = () => {
  return Axios.get(process.env.REACT_APP_SERVER_ROOT + '/sales-bonus/template', {
    responseType: 'blob',
  });
};

const GetKPIAdjustments = (data) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/kpiAdjustments', data);
};

const GetKPIAdjustmentRequest = (data) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/kpiAdjustments/get-request', data);
};

const CreateKPIAdjustmentRequest = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/kpiAdjustments/create-request', formData);
};

const UploadKpiAdjusmentFile = (data: FormData) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/kpiAdjustments/upload-file', data);
};

const ApproveKPIAdjustmentRequest = (formData: any) => {
  return Axios.post(process.env.REACT_APP_SERVER_ROOT + '/kpiAdjustments/approve', formData);
};

const DeleteKPIAdjustmentRequest = (id: any) => {
  return Axios.delete(process.env.REACT_APP_SERVER_ROOT + '/kpiAdjustments/delete-request/' + id);
};

const salesCompService = {
  ImportSalesBonus,
  ImportCompRollUp,
  SendBonusEmails,
  GetSalesComp,
  GetSalesCompDetail,
  GetSalesRollUp,
  GetTemplate,
  DeleteSalesComp,
  GetKPIAdjustments,
  CreateKPIAdjustmentRequest,
  GetKPIAdjustmentRequest,
  ApproveKPIAdjustmentRequest,
  DeleteKPIAdjustmentRequest,
  UploadKpiAdjusmentFile,
};

export default salesCompService;
