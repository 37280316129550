import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Form, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ForecastService from '../../services/forecast.service';
import { ImportType, ITerritory } from '../../lib/types';
import CustomSelect from '../../components/common/CustomSelect';
import AdminService from '../../services/admin.service';
import { hideLoading, showLoading } from '../../lib/uiService';
import { downloadFile } from '../../utils/downloadUtils';
import { ImportExportCard } from '../../components/import-export/ImportExportCard';
import { ErrorMessage, Field, Formik } from 'formik';
import { date, object } from 'yup';
import { formatNumOneDecimal, formatNum } from '../../utils/formatNum';

const ImportExport: React.FC = () => {
  const [territories, setTerritories] = useState([]);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isResultModalOpen, setIsResultModalOpen] = useState(false);
  const [isSyncSalesGoalModalOpen, $isSyncSalesGoalModalOpen] = useState(false);
  const [resultData, setResultData] = useState({
    casesDeleted: 0,
    deletedCount: 0,
    invoicesCases: 0,
    invoicesCount: 0,
  });
  const [brands, setBrands] = useState([]);
  const [imporFile, setFile] = useState<any>({
    file: '',
    type: '',
  });
  const [salesFilter, setSalesFilter] = useState<any>({
    brandId: '',
    territoryId: '',
    toDate: '',
    fromDate: '',
  });
  const [productFilter, setProductFilter] = useState({
    brandId: '',
  });
  const [pricingFilter, setPricingFilter] = useState({
    brandId: '',
    territoryId: '',
  });
  const filter = {
    Sales: salesFilter,
    Product: productFilter,
    Pricing: pricingFilter,
  };
  const setfilter = {
    Sales: setSalesFilter,
    Product: setProductFilter,
    Pricing: setPricingFilter,
  };
  const navigate = useNavigate();

  const handleFile = (e: any, type: ImportType) => {
    const file = e.target.files && e.target.files[0];
    if (!file) {
      toast.warn('Please select a file');
      return;
    }
    const fileExtension = file.name.split('.').pop()?.toLowerCase();
    if (fileExtension !== 'xlsx') {
      toast.warn('File type must be .xlsx');
      return;
    }
    setFile({ file, type });
  };

  const onImportData = (type: ImportType) => {
    if (!imporFile.file) {
      toast.warn(`Please select a ${type} File`);
      return;
    }
    if (imporFile.type == type) {
      navigate(`/admin/import-confirm/?${type}`, {
        state: imporFile.file,
      });
    }
  };

  const validation = () => {
    if (!salesFilter.fromDate) {
      return `Please input Start Date`;
    }
    if (!salesFilter.toDate) {
      return 'Please input End Date';
    }
    if (new Date(salesFilter.fromDate) >= new Date(salesFilter.toDate)) {
      return `Start date must be before end date`;
    }

    return '';
  };

  const onExportData = async (type: ImportType) => {
    const msg = validation();
    if (msg && type == ImportType.Sales) {
      toast.warn(msg);
    } else {
      showLoading();
      const exportService = AdminService.exportServices[type];
      const res = await exportService(filter[type]);
      const { label: productLabel } =
        brands.find(({ value }) => value === filter[type].brandId) ?? ({ label: 'All' } as any);
      const { label: pricingLabel } =
        territories.find(({ value }) => value === filter[type].territoryId) ?? ({ label: 'All' } as any);
      switch (type) {
        case ImportType.Sales:
          downloadFile(res, `Sales-Record(${filter[type].fromDate as string}-${filter[type].toDate as string}).xlsx`);
          break;
        case ImportType.Product:
          downloadFile(res, `Product-Record(${productLabel as string}).xlsx`);
          break;
        case ImportType.Pricing:
          downloadFile(res, `Product-Price(${pricingLabel as string}).xlsx`);
          break;
      }
      hideLoading();
    }
  };

  const handleBrand = (value: string, type: ImportType) => {
    setfilter[type]({ ...filter[type], brandId: value });
  };

  const handleTerritory = (value: string, type: ImportType) => {
    setfilter[type]({ ...filter[type], territoryId: value });
  };

  useEffect(() => {
    showLoading();
    ForecastService.GetTerritories()
      .then(({ data }) => {
        const tempTerritories = data.map((region: ITerritory) =>
          Object.assign({ label: region.name, value: region.territoryId }),
        );
        tempTerritories.unshift({ label: 'All Regions', value: '' });
        setTerritories(tempTerritories);
      })
      .catch((error) => console.error(error));
    ForecastService.GetBrands()
      .then(({ data }) => {
        const brands = data.brands.map((brand: any) => Object.assign({ label: brand.name, value: brand.brandId }));
        brands.unshift({ label: 'All Brands', value: '' });
        setBrands(brands);
        hideLoading();
      })
      .catch((error) => console.error(error));
    hideLoading();
  }, []);

  const seedParkStreet = async (values) => {
    showLoading();
    AdminService.SeedSales(values)
      .then(({ data }) => {
        hideLoading();
        setResultData(data);
        setIsResultModalOpen(true);
      })
      .catch((error) => {
        hideLoading();
        console.error(error.message);
        toast.error('There was an error with your request, please try again');
      });
  };

  const seedSalesGoal = async (values) => {
    showLoading();
    AdminService.SeedSalesGoal(values)
      .then(({ data }) => {
        hideLoading();
        setResultData(data);
        $isSyncSalesGoalModalOpen(true);
      })
      .catch((error) => {
        hideLoading();
        console.error(error.message);
        toast.error('There was an error with your request, please try again');
      });
  };

  const exportForecastRender = async () => {
    showLoading();
    AdminService.ManualForecastExport()
      .then(() => {
        hideLoading();
        toast.success('Forecast successfully exported');
      })
      .catch((error) => {
        hideLoading();
        toast.error(error.message ? error.message : 'There was an error with your request, please try again');
      });
  };

  return (
    <div>
      <h3 className="text-dark mb-4">Import/Export</h3>
      <div className="row">
        <div className="col-4">
          <ImportExportCard
            title={ImportType.Sales}
            importBody={
              <InputGroup className="mt-3">
                <Form.Control type="file" onChange={(e) => handleFile(e, ImportType.Sales)} />
                <Button
                  disabled={!imporFile.file && imporFile.type == ImportType.Sales}
                  onClick={() => onImportData(ImportType.Sales)}
                  variant="primary"
                  id="button-addon2"
                >
                  Upload
                </Button>
              </InputGroup>
            }
            exportBody={
              <div>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">Brand</InputGroup.Text>
                  <CustomSelect options={brands} type={ImportType.Sales} onSelectOption={handleBrand} />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">Region</InputGroup.Text>
                  <CustomSelect options={territories} type={ImportType.Sales} onSelectOption={handleTerritory} />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">Start Date</InputGroup.Text>
                  <Form.Control
                    onChange={(e) => setSalesFilter({ ...salesFilter, fromDate: e.target.value })}
                    type="date"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">End Date</InputGroup.Text>
                  <Form.Control
                    onChange={(e) => setSalesFilter({ ...salesFilter, toDate: e.target.value })}
                    type="date"
                    aria-label="Default"
                    aria-describedby="inputGroup-sizing-default"
                  />
                </InputGroup>
                <div className="d-flex justify-content-end mt-3">
                  <Button onClick={() => onExportData(ImportType.Sales)} variant="primary" id="button-addon2">
                    Export
                  </Button>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-4">
          <ImportExportCard
            title={ImportType.Product}
            importBody={
              <InputGroup className="mt-3">
                <Form.Control type="file" onChange={(e) => handleFile(e, ImportType.Product)} />
                <Button
                  disabled={!imporFile.file && imporFile.type == ImportType.Product}
                  onClick={() => onImportData(ImportType.Product)}
                  variant="primary"
                  id="button-addon2"
                >
                  Upload
                </Button>
              </InputGroup>
            }
            exportBody={
              <div>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">Brand</InputGroup.Text>
                  <CustomSelect options={brands} type={ImportType.Product} onSelectOption={handleBrand} />
                </InputGroup>
                <div className="d-flex justify-content-end mt-3">
                  <Button onClick={() => onExportData(ImportType.Product)} variant="primary" id="button-addon2">
                    Export
                  </Button>
                </div>
              </div>
            }
          />
        </div>
        <div className="col-4">
          <ImportExportCard
            title={ImportType.Pricing}
            importBody={
              <InputGroup className="mt-3">
                <Form.Control type="file" onChange={(e) => handleFile(e, ImportType.Pricing)} />
                <Button
                  disabled={!imporFile.file && imporFile.type == ImportType.Pricing}
                  onClick={() => onImportData(ImportType.Pricing)}
                  variant="primary"
                  id="button-addon2"
                >
                  Upload
                </Button>
              </InputGroup>
            }
            exportBody={
              <div>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">Brand</InputGroup.Text>
                  <CustomSelect options={brands} type={ImportType.Pricing} onSelectOption={handleBrand} />
                </InputGroup>
                <InputGroup>
                  <InputGroup.Text id="inputGroup-sizing-default">Region</InputGroup.Text>
                  <CustomSelect options={territories} type={ImportType.Pricing} onSelectOption={handleTerritory} />
                </InputGroup>
                <div className="d-flex justify-content-end mt-3">
                  <Button onClick={() => onExportData(ImportType.Pricing)} variant="primary" id="button-addon2">
                    Export
                  </Button>
                </div>
              </div>
            }
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <Card className="d-flex p-4">
            <div>
              <h4 className="fw-bold m-0 pb-1 pt-1">Render</h4>
            </div>
            <div className="my-2">
              <div className="d-flex float-end mt-3">
                <Button onClick={exportForecastRender} variant="primary" id="button-addon2">
                  Export Forecasts
                </Button>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-4">
          <Card className="d-flex p-4">
            <div>
              <h4 className="fw-bold m-0 pb-1 pt-1">Park Street</h4>
            </div>
            <div className="my-2">
              <h5>Refresh Data</h5>
              <div>
                <Formik
                  initialValues={{ toDate: '', fromDate: '' }}
                  onSubmit={() => {
                    setIsConfirmationModalOpen(true);
                  }}
                  validationSchema={object().shape({
                    fromDate: date().required('Required'),
                    toDate: date()
                      .required('Required')
                      .test('is-greater', 'To date must be later than From date', function (value) {
                        const { fromDate } = this.parent;
                        return value > fromDate;
                      }),
                  })}
                  validateOnChange={false}
                  validateOnBlur
                >
                  {({ values, submitForm }) => (
                    <Form>
                      <label className="col-form-label">From</label>
                      <Field
                        type="date"
                        className="form-control"
                        name="fromDate"
                        max={new Date().toISOString().split('T')[0]}
                      />
                      <div style={{ fontSize: '10px', color: 'red' }}>
                        <ErrorMessage name="fromDate" />
                      </div>
                      <label className="col-form-label">To</label>
                      <Field
                        type="date"
                        className="form-control"
                        name="toDate"
                        max={new Date().toISOString().split('T')[0]}
                      />
                      <div style={{ fontSize: '10px', color: 'red' }}>
                        <ErrorMessage name="toDate" />
                      </div>
                      <button
                        className="btn btn-primary float-end mt-3"
                        onClick={(e) => {
                          e.preventDefault();
                          submitForm();
                        }}
                        type="submit"
                      >
                        Refresh
                      </button>
                      <Modal show={isConfirmationModalOpen} onHide={() => setIsConfirmationModalOpen(false)} centered>
                        <Modal.Header closeButton>Confirm</Modal.Header>
                        <Modal.Body className="text-center m-3">
                          <h6>
                            This will clear all Sales data for the period selected. Are you sure you want to continue?
                          </h6>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            disabled={!isConfirmationModalOpen}
                            onClick={async () => {
                              setIsConfirmationModalOpen(false);
                              seedParkStreet(values);
                            }}
                          >
                            Refresh
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
        <div className="col-4">
          <Card className="d-flex p-4">
            <div>
              <h4 className="fw-bold m-0 pb-1 pt-1">Sales Goal</h4>
            </div>
            <div className="my-2">
              <h5>Sync Data</h5>
              <div>
                <Formik
                  initialValues={{ toDate: '', fromDate: '' }}
                  onSubmit={() => {
                    setIsConfirmationModalOpen(true);
                  }}
                  validationSchema={object().shape({
                    fromDate: date().required('Required'),
                    toDate: date()
                      .required('Required')
                      .test('is-greater', 'To date must be later than From date', function (value) {
                        const { fromDate } = this.parent;
                        return value > fromDate;
                      }),
                  })}
                  validateOnChange={false}
                  validateOnBlur
                >
                  {({ values, submitForm }) => (
                    <Form>
                      <label className="col-form-label">From</label>
                      <Field
                        type="date"
                        className="form-control"
                        name="fromDate"
                        max={new Date().toISOString().split('T')[0]}
                      />
                      <div style={{ fontSize: '10px', color: 'red' }}>
                        <ErrorMessage name="fromDate" />
                      </div>
                      <label className="col-form-label">To</label>
                      <Field
                        type="date"
                        className="form-control"
                        name="toDate"
                        max={new Date().toISOString().split('T')[0]}
                      />
                      <div style={{ fontSize: '10px', color: 'red' }}>
                        <ErrorMessage name="toDate" />
                      </div>
                      <button
                        className="btn btn-primary float-end mt-3"
                        onClick={(e) => {
                          e.preventDefault();
                          submitForm();
                        }}
                        type="submit"
                      >
                        Sync
                      </button>
                      <Modal show={isConfirmationModalOpen} onHide={() => setIsConfirmationModalOpen(false)} centered>
                        <Modal.Header closeButton>Confirm</Modal.Header>
                        <Modal.Body className="text-center m-3">
                          <h6>
                            This will sync all Sales Goal data from GreatVines for the selected period. Are you sure you
                            want to continue?
                          </h6>
                        </Modal.Body>
                        <Modal.Footer>
                          <Button
                            variant="secondary"
                            disabled={!isConfirmationModalOpen}
                            onClick={async () => {
                              setIsConfirmationModalOpen(false);
                              seedSalesGoal(values);
                            }}
                          >
                            Sync
                          </Button>
                        </Modal.Footer>
                      </Modal>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <Modal show={isResultModalOpen} onHide={() => setIsResultModalOpen(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center m-2">
          <h3 className="m-3">Sales successfully seeded</h3>
          <h6>Deleted records: {formatNumOneDecimal(Number(resultData.deletedCount))}</h6>
          <h6>Deleted cases: {formatNumOneDecimal(Number(resultData.casesDeleted))}</h6>
          <h6>Created records: {formatNumOneDecimal(Number(resultData.invoicesCount))}</h6>
          <h6>Created cases: {formatNumOneDecimal(Number(resultData.invoicesCases))}</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={async () => {
              setIsResultModalOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal show={isSyncSalesGoalModalOpen} onHide={() => $isSyncSalesGoalModalOpen(false)} centered>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="text-center m-2">
          <h3 className="m-3">Sales Goal data has been synchronized successfully</h3>
          <h6>Total records: {formatNum(Number(resultData.invoicesCount), 0, 0)}</h6>
          <h6>Processed records: {formatNum(Number(resultData.invoicesCases), 0, 0)}</h6>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={async () => {
              $isSyncSalesGoalModalOpen(false);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ImportExport;
