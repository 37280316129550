import React, { useState } from 'react';
import { useField, useFormikContext } from 'formik';
import { useDropzone } from 'react-dropzone';
import { ListGroup, Carousel, Modal } from 'react-bootstrap';

export const ImageInput = (props: any) => {
  const {
    field: { name },
    disabled,
  } = props;
  const {
    actions = {
      download: true,
    },
  } = props;
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const [files, setFiles] = useState<any[]>([]);
  const [showCarousel, setShowCarousel] = useState(false);
  const [carouselIndex, setCarouselIndex] = useState(0);

  const onDrop = (acceptedFiles: any[]) => {
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      ),
    );
    setFieldValue(name, acceptedFiles);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: ['image/*'],
    onDrop,
  });

  const handleDelete = (index: number) => {
    const newFiles = files.filter((_, i) => i !== index);
    setFiles(newFiles);
    setFieldValue(name, newFiles);
  };

  const handleDownload = (file: any) => {
    const link = document.createElement('a');
    link.href = file.preview;
    link.download = file.name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleShowCarousel = (index: number) => {
    setCarouselIndex(index);
    setShowCarousel(true);
  };

  const handleCarouselSelect = (selectedIndex: number) => {
    setCarouselIndex(selectedIndex);
  };

  return (
    <div className="my-2">
      {!disabled && (
        <div
          {...getRootProps({
            className: `dropzone ${isDragActive ? 'dropzone-active' : ''}`,
          })}
          style={{
            border: '2px dashed #cccccc',
            padding: '12px',
            textAlign: 'center',
            backgroundColor: isDragActive ? '#f0f0f0' : 'transparent',
          }}
        >
          <input {...getInputProps()} />
          <div>Drag & drop some files here, or click to select files</div>
        </div>
      )}
      {meta.touched && meta.error ? <div className="error">{meta.error}</div> : null}
      <ListGroup className="image-list">
        {files.map((file, index) => (
          <ListGroup.Item key={index} className="position-relative d-flex align-items-center">
            <img
              src={file.preview}
              alt={`Preview ${index}`}
              style={{ width: '40px', height: '40px', objectFit: 'cover', cursor: 'pointer' }}
              onClick={() => handleShowCarousel(index)}
            />
            <div className="ms-3 flex-grow-1" style={{ cursor: 'pointer' }}>
              <div onClick={() => handleShowCarousel(index)}>{file.name}</div>
            </div>
            <div className="position-absolute" style={{ bottom: '10px', right: '10px' }}>
              {actions?.download && (
                <button
                  className="btn btn-primary btn-sm me-2"
                  type="button"
                  style={{ height: '23px', fontSize: '0.7rem' }}
                  onClick={() => handleDownload(file)}
                >
                  Download
                </button>
              )}
              {!disabled && (
                <button
                  className="btn btn-danger btn-sm"
                  type="button"
                  style={{ height: '23px', fontSize: '0.7rem' }}
                  onClick={() => handleDelete(index)}
                >
                  Delete
                </button>
              )}
            </div>
          </ListGroup.Item>
        ))}
      </ListGroup>
      <Modal show={showCarousel} onHide={() => setShowCarousel(false)} size="lg" centered>
        <Modal.Body>
          <Carousel activeIndex={carouselIndex} onSelect={handleCarouselSelect}>
            {files.map((file, index) => (
              <Carousel.Item key={index}>
                <img
                  className="d-block w-100"
                  src={file.preview}
                  alt={`Slide ${index}`}
                  style={{ height: '500px', objectFit: 'contain' }}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ImageInput;
