import React, { useEffect, useState } from 'react';
import salesCompService from '../../../services/sales.bonus.service';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { useNavigate } from 'react-router-dom';
import Pagination from '../../../components/Pagination';
import { useAuth } from '../../../providers/AuthProvider';
import { UserRole } from '../../../lib/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquarePlus } from '@fortawesome/free-solid-svg-icons';
import CustomSelect from '../../../components/common/CustomSelect';
import { ApprovalStatus } from '../../../constants';

const KpiAdjustments = () => {
  const { setError } = useErrorHandling();
  const { role, id } = useAuth();
  const navigate = useNavigate();
  const [userKpiAdjustments, setUserKpiAdjustments] = useState<any[]>([]);
  const [adminKpiAdjustments, setAdminKpiAdjustments] = useState<any[]>([]);
  const [count, setCount] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const [startPage, setStartPage] = useState<number>(1);
  const [totalPages, setTotalPages] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [pageSize, setPageSize] = useState<number>(Number.MAX_SAFE_INTEGER);
  const [currentStartPage, setCurrentStartPage] = useState(1);
  const [fetchAgain, setFetchAgain] = useState(true);
  const pageOptions = [
    { label: 'All', value: Number.MAX_SAFE_INTEGER },
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];
  useEffect(() => {
    setStartPage(1);
  }, [pageSize]);

  useEffect(() => {
    if (fetchAgain) {
      salesCompService
        .GetKPIAdjustments({ page, pageSize })
        .then(({ data }) => {
          setCount(data?.count);
          setTotalPages(Math.ceil(data?.count / pageSize));
          setFetchAgain(false);
          setUserKpiAdjustments(data.userKpiAdjustments || []);
          setAdminKpiAdjustments(data.kpiAdjustmentsToApprove || []);
        })
        .catch((error) => {
          console.error(error);
          setError({ status: error.response.status });
        });
    }
  }, [fetchAgain]);
  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">KPI Adjustments</h3>
      <div className="row">
        <div className="col">
          {role?.includes(UserRole.Admin) ||
          role?.includes(UserRole.SalesBonusAdmin) ||
          role?.includes(UserRole.SalesBonusUser) ? (
            <div
              className="card shadow"
              style={{ padding: '0px', paddingBottom: '0px', marginBottom: '40px', paddingRight: '0px' }}
            >
              <div className="card-header py-3">
                <p className="fw-bold text-primary m-0">Adjustments Requiring Approval</p>
              </div>
              <div className="card-body">
                {adminKpiAdjustments.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Team Member</th>
                          <th>Goal</th>
                          <th>Account</th>
                          <th>Time Period</th>
                          <th>Status</th>
                          <th>Approved By</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {adminKpiAdjustments?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>
                                <a href="#">
                                  {item?.user?.firstName} {item?.user?.lastName}
                                </a>
                              </td>
                              <td>
                                <a href={`kpi-adjustment/approve?kpi=${item.kpiAdjustmentId}`}>{item.kpi}</a>
                              </td>
                              <td>
                                <a href={item.accountURL}>{item.accountName}</a>
                              </td>
                              <td>{item.timePeriod}</td>
                              <td>
                                {item?.approvals.some(
                                  (approval) => approval?.approvalStatus === ApprovalStatus.REJECTED,
                                )
                                  ? 'Rejected'
                                  : Number(item.approvalCount) === 0
                                    ? 'Pending'
                                    : Number(item.approvalCount) < Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT)
                                      ? 'Pending'
                                      : 'Approved'}
                              </td>
                              <td>
                                {item.approvals.map((approval, index) => (
                                  <span
                                    key={index}
                                    className={`badge bg-${approval?.approvalStatus === ApprovalStatus.REJECTED ? 'danger' : Number(item.approvalCount) === Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT) && !approval?.weasRejected ? 'success' : 'warning'} me-2`}
                                  >
                                    {approval?.user?.firstName} {approval?.user?.lastName}
                                  </span>
                                ))}
                              </td>
                              <td>
                                {Number(item.approvalCount) < Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT) &&
                                !item?.approvals.some(
                                  (approval) => approval?.approvalStatus === ApprovalStatus.REJECTED,
                                ) ? (
                                  <button
                                    disabled={item?.approvals.some((item) => item.createdBy === id)}
                                    className="btn btn-primary btn-sm"
                                    type="button"
                                    onClick={() => {
                                      navigate(`/sales-commission/kpi-adjustment/approve?kpi=${item.kpiAdjustmentId}`);
                                    }}
                                  >
                                    Approve
                                  </button>
                                ) : null}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div style={{ textAlign: 'center' }}>
                    <h4>No KPI Adjustment Requests at this time</h4>
                  </div>
                )}
              </div>
            </div>
          ) : null}
          <div
            className="card shadow"
            style={{ padding: '0px', paddingBottom: '0px', marginBottom: '40px', paddingRight: '0px' }}
          >
            <div className="card-header py-3">
              <p className="fw-bold text-primary m-0">
                <button
                  className="btn btn-primary float-end"
                  type="button"
                  onClick={() => {
                    navigate('/sales-commission/kpi-adjustment/create');
                  }}
                >
                  <FontAwesomeIcon icon={faSquarePlus} />
                  &nbsp;Request New KPI Adjustment
                </button>
                KPI Adjustments History
              </p>
            </div>
            <div className="card-body">
              <div className="col-md-6 text-nowrap">
                <div id="dataTable_length-1" className="dataTables_length" aria-controls="dataTable">
                  <label className="form-label">
                    Show&nbsp;
                    <CustomSelect
                      defaultValue={'All'}
                      options={pageOptions}
                      onSelectOption={(value) => {
                        setFetchAgain(true);
                        setPageSize(Number(value));
                      }}
                      setPage={setPage}
                    />
                    &nbsp;
                  </label>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Goal</th>
                      <th>Account</th>
                      <th>Time Period</th>
                      <th>Status</th>
                      <th>Approved By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userKpiAdjustments?.map((item, index) => {
                      return (
                        <tr key={index}>
                          <td>
                            <a href={`kpi-adjustment/create?kpi=${item.kpiAdjustmentId}`}>{item.kpi}</a>
                          </td>
                          <td>
                            <a href={item.accountURL}>{item.accountName}</a>
                          </td>
                          <td>{item.timePeriod}</td>
                          <td>
                            {item?.approvals.some((approval) => approval?.approvalStatus === ApprovalStatus.REJECTED)
                              ? 'Rejected'
                              : Number(item.approvalCount) === 0
                                ? 'Pending'
                                : Number(item.approvalCount) < Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT)
                                  ? 'Pending'
                                  : 'Approved'}
                          </td>
                          <td>
                            {item.approvals.map((approval, index) => (
                              <span
                                key={index}
                                className={`badge bg-${approval?.approvalStatus === ApprovalStatus.REJECTED ? 'danger' : Number(item.approvalCount) === Number(process.env.REACT_APP_PRICING_APPROVAL_COUNT) && !approval?.weasRejected ? 'success' : 'warning'} me-2`}
                              >
                                {approval?.user?.firstName} {approval?.user?.lastName}
                              </span>
                            ))}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
              <div className="row">
                <div className="col-md-6 align-self-center">
                  <p id="dataTable_info-3" className="dataTables_info" role="status" aria-live="polite">
                    Showing {pageSize > count ? count : pageSize} of {count}
                  </p>
                </div>
                <div className="col-md-6">
                  <nav className="d-lg-flex justify-content-lg-end dataTables_paginate paging_simple_numbers">
                    <ul className="pagination">
                      <li className={'page-item cursor-pointer' + (page - 1 > 0 ? '' : ' disabled')}>
                        <div
                          className="page-link"
                          aria-label="Previous"
                          onClick={() => {
                            if (page > 1) {
                              setPage(page - 1);
                              if (page === currentStartPage) {
                                setCurrentStartPage(Math.max(currentStartPage - 1, 1));
                              }
                              setFetchAgain(true);
                            }
                          }}
                        >
                          <span aria-hidden="true">«</span>
                        </div>
                      </li>
                      <Pagination
                        currentStartPage={currentStartPage}
                        totalPages={totalPages}
                        startPage={startPage}
                        pageSize={pageSize}
                        count={count}
                        page={page}
                        setPage={setPage}
                        setData={setUserKpiAdjustments}
                        initialData={userKpiAdjustments}
                        setCurrentStartPage={setCurrentStartPage}
                      />
                      <li className={'page-item cursor-pointer' + (page + 1 <= totalPages ? '' : ' disabled')}>
                        <div
                          className="page-link"
                          aria-label="Next"
                          onClick={() => {
                            setPage(page + 1);
                            setCurrentStartPage(currentStartPage + 1);
                            setFetchAgain(true);
                          }}
                        >
                          <span aria-hidden="true">»</span>
                        </div>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiAdjustments;
