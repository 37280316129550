import React, { useEffect, useState } from 'react';
import { formatDate } from '../../../utils/formatDate';
import { hideLoading, showLoading } from '../../../lib/uiService';
import salesCompService from '../../../services/sales.bonus.service';
import useErrorHandling from '../../../hooks/useErrorHandling';
import { useAuth } from '../../../providers/AuthProvider';
import { kpiAdjustmentsTypes } from '../../../constants';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { UserRole } from '../../../lib/types';
import AdminService from '../../../services/admin.service';
import ImageInput from '../../../components/common/ImageInput';
import { round } from '../../../utils/formatNum';

const calcNewAchievedPercent = (selectedGoal: any, requestedChange: number) => {
  const amount = round((requestedChange / selectedGoal.goalQuantity) * 100, 2);
  return amount;
};

const NewKpiAdjustmentRequest = () => {
  const { setError } = useErrorHandling();
  const { id, role, isCheckingLoggedState } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [periods, setPeriods] = useState<any[]>([]);
  const [goals, setGoals] = useState<any[]>([]);
  const [disable, setDisable] = useState(false);
  const [selectedGoal, setSelectedGoal] = useState<any>({});
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [newKpiAdjustment, setNewKpiAdjustment] = useState<any>({});
  const [searchParams] = useSearchParams();
  const paramKpiAdjustmentId = searchParams.get('kpi');
  const isKpiRequestForm = `${location.pathname}`.includes('create');
  const isKpiApproveForm = `${location.pathname}`.includes('approve');
  const isKpiEditForm = isKpiRequestForm && !!paramKpiAdjustmentId;
  const isApprovedRequest = !!newKpiAdjustment?.approvals?.length;
  const isApprover = role && (role.includes(UserRole.Admin) || role.includes(UserRole.SalesBonusAdmin));
  const isAdmin = role && role.includes(UserRole.Admin);
  const stateRef = React.useRef<any>({});
  Object.assign(stateRef.current, { isKpiRequestForm, isKpiApproveForm, userId: id, role, isCheckingLoggedState });

  const validationSchema = Yup.object({
    compSalesId: Yup.string().required('Time Period is required'),
    goal: Yup.string().required('Goal is required'),
    accountName: Yup.string().required('Account Name is required'),
    // accountUrl: Yup.string().required('Account URL is required'),
    accountUrl: Yup.string()
      .required('Account URL is required')
      .matches(/^(https:\/\/)?[a-zA-Z0-9.-]+\.salesforce\.com/, 'Account URL must start with a Salesforce URL'),
    requestedChange: Yup.number()
      .typeError('Requested Change must be a number')
      .positive('Requested change must be a positive number')
      .required('Requested adjustment is required'),
    reasons: Yup.string().required('Reason for adjustment is required'),
    nonClassB: Yup.boolean(),
    attachments: Yup.mixed().when('nonClassB', (nonClassB, schema) => {
      return nonClassB && nonClassB[0]
        ? schema
            .required('For Non-Class B adjustments, please upload image proof')
            .test('fileSize', 'File size is too large (Max 1MB)', (value: any) => {
              const found = (value || []).find((value) => value && value.size >= 1024 * 1024);
              return !found;
            })
            .test('fileFormat', 'Unsupported file format. JPG and PNG formats supported', (value: any) => {
              const found = (value || []).find(
                (value) => value && !['image/jpg', 'image/jpeg', 'image/png'].includes(value.type),
              );
              return !found;
            })
        : schema.nullable();
    }),
  });

  useEffect(() => {
    showLoading();
    salesCompService
      .GetSalesComp()
      .then(({ data }) => {
        if (data.length > 0) {
          const tempSalesCompList = data.map((comp: any) => {
            const startDate = formatDate(
              { month: '2-digit', day: '2-digit', year: 'numeric' },
              new Date(comp.startDate),
            );
            const endDate = formatDate({ month: '2-digit', day: '2-digit', year: 'numeric' }, new Date(comp.endDate));
            return { label: `${startDate ?? ''} - ${endDate ?? ''}`, value: comp.salesCompId };
          });
          setPeriods(tempSalesCompList);
        }
        hideLoading();
      })
      .catch((error) => {
        hideLoading();
        console.error(error);
        setError({ status: error.response.status });
      });
  }, []);

  useEffect(() => {
    if (paramKpiAdjustmentId && !isCheckingLoggedState) {
      salesCompService
        .GetKPIAdjustmentRequest({ kpiAdjustmentId: paramKpiAdjustmentId })
        .then(async ({ data }) => {
          if (data.userKpiAdjustmentRequest) {
            const saleCompId = data.userKpiAdjustmentRequest.salesCompGroup?.salesCompId;
            const userId = data.userKpiAdjustmentRequest.userId;
            if (saleCompId) {
              await loadSalesCompDetail(saleCompId, userId);
            }
            // Once the request has been submitted, it can't be edited by the requester.  It should be editable by the approver.
            setDisable(isKpiApproveForm || isKpiEditForm);
            setNewKpiAdjustment({
              ...data.userKpiAdjustmentRequest,
              goalId: data.userKpiAdjustmentRequest.goalId,
              goal: data.userKpiAdjustmentRequest.kpi,
              accountUrl: data.userKpiAdjustmentRequest.accountURL,
              requestedChange: data.userKpiAdjustmentRequest.newGoal,
              reasons: data.userKpiAdjustmentRequest.adjustmentReason,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          setError({ status: error.response.status });
        });
    }
  }, [paramKpiAdjustmentId, isCheckingLoggedState]);

  const requestNewKpiAdjustment = async (values) => {
    const updatedValues = { ...newKpiAdjustment, ...values };
    if (isKpiApproveForm) {
      showLoading();
      salesCompService
        .ApproveKPIAdjustmentRequest({ kpiAdjustment: updatedValues })
        .then(({ data }) => {
          hideLoading();
          toast.success(
            data.wasApproved
              ? 'This request has been approved.'
              : 'You have approved these request. Awaiting more approvals.',
          );
          navigate('/sales-commission/kpi-adjustment');
        })
        .catch((error) => {
          hideLoading();
          console.error(error);
          setError({ status: error.response.status });
        });
    } else {
      setErrorMessage('');
      showLoading();
      try {
        const attachments = [];
        if (values.nonClassB) {
          await Promise.all(
            (values.attachments || []).map(async (attachment) => {
              const formData = new FormData();
              formData.append('image', attachment);
              const fileData = await salesCompService.UploadKpiAdjusmentFile(formData);
              attachments.push(fileData.data);
            }),
          );
        }
        await salesCompService.CreateKPIAdjustmentRequest({
          newKpiAdjustment: {
            ...updatedValues,
            attachments,
          },
          selectedGoal,
        });
        hideLoading();
        toast.success('KPI Adjustment Request Successfully Created');
        navigate('/sales-commission/kpi-adjustment');
      } catch (error) {
        hideLoading();
        console.error(error);
        setError({ status: error.response.status });
      }
    }
  };

  const loadSalesCompDetail = async (compSalesId: string, userId?: string) => {
    try {
      const id = userId || stateRef.current.userId;
      const { data } = await salesCompService.GetSalesCompDetail(compSalesId);
      if (data) {
        const compDetail = data.groups.find((item) => item.userId === id);
        if (compDetail) {
          setGoals(
            compDetail.items
              .map((item) => {
                return !item.goalPlan.toLowerCase().includes('total') ? item : null;
              })
              .filter((item) => item),
          );
        } else {
          setErrorMessage("There isn't a goal related to the current user.");
        }
      } else {
        salesCompService
          .GetSalesRollUp(compSalesId)
          .then(({ data }) => {
            if (data.salesComp) {
              const compDetail = data.salesComp.groups.find((item) => item.userId === id);
              if (compDetail) {
                setGoals(
                  compDetail.items
                    .map((item) => {
                      return !item.goalPlan.toLowerCase().includes('total') ? item : null;
                    })
                    .filter((item) => item),
                );
              } else {
                setErrorMessage("There isn't a goal related to the current user.");
              }
            } else {
              setGoals([]);
            }
          })
          .catch((error) => {
            console.error(error);
            setError({ status: error.response.status });
          });
      }
    } catch (error) {
      console.error(error);
      setError({ status: error.response.status });
    }
  };
  useEffect(() => {
    const goal = goals.find((item) => item.goalPlan === newKpiAdjustment.goal);
    if (goal) {
      setSelectedGoal(goal);
    }
  }, [newKpiAdjustment, goals]);

  if (isCheckingLoggedState) return null;
  if (paramKpiAdjustmentId && !newKpiAdjustment.kpiAdjustmentId) return null;

  const goal = goals.find((item) => item.goalPlan === newKpiAdjustment.goal);

  return (
    <div className="container-fluid">
      <h3 className="text-dark mb-4">KPI Adjustment</h3>
      <div className="row">
        <div className="col">
          <div
            className="card shadow"
            style={{
              padding: '0px',
              paddingBottom: '0px',
              marginBottom: '40px',
              paddingRight: '0px',
            }}
          >
            <div className="card-header py-3">
              <p className="fw-bold text-primary m-0">
                {isKpiRequestForm ? 'Request a new KPI Adjustment Request' : ''}
                {isKpiApproveForm
                  ? `Approve a KPI Adjustment Request for ${[newKpiAdjustment.user.firstName, newKpiAdjustment.user.lastName].filter(Boolean).join(' ')}`
                  : ''}
              </p>
            </div>
            <div className="card-body">
              <Formik
                enableReinitialize
                initialValues={{
                  compSalesId: newKpiAdjustment?.salesCompGroup?.salesCompId,
                  goal,
                  accountName: newKpiAdjustment.accountName,
                  accountUrl: newKpiAdjustment.accountUrl,
                  requestedChange: newKpiAdjustment.requestedChange || 0,
                  adjustmentType: kpiAdjustmentsTypes.Adjustment,
                  reasons: newKpiAdjustment.reasons,
                  approverNotes: newKpiAdjustment.approverNotes,
                  attachments: newKpiAdjustment.attachments || [],
                  nonClassB: newKpiAdjustment.nonClassB || false,
                }}
                validationSchema={validationSchema}
                onSubmit={requestNewKpiAdjustment}
              >
                {({ values, handleChange, setFieldValue }) => {
                  return (
                    <Form>
                      <div className="row">
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">
                              <strong>Time Period</strong>
                            </label>
                            <Field
                              as="select"
                              name="compSalesId"
                              className="form-select"
                              disabled={disable}
                              value={values.compSalesId}
                              onChange={(e) => {
                                const compSalesId = e.target.value;
                                handleChange(e);
                                setFieldValue('compSalesId', compSalesId);
                                if (compSalesId !== '') {
                                  loadSalesCompDetail(compSalesId);
                                } else {
                                  setNewKpiAdjustment({ ...newKpiAdjustment, goal: '', requestedChange: '' });
                                  setGoals([]);
                                  setSelectedGoal({});
                                }
                              }}
                            >
                              <option value=""> - </option>
                              {periods.map((item, index) => (
                                <option key={index} value={item.value}>
                                  {item.label}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="compSalesId" component="div" className="text-danger" />
                          </div>
                          <div className="form-group">
                            <label className="form-label">
                              <strong>Goal</strong>
                            </label>
                            <Field
                              as="select"
                              name="goal"
                              className="form-select"
                              disabled={disable || !values.compSalesId}
                              value={values.goal?.goalPlan}
                              onChange={(e) => {
                                const goal = e.target.value;
                                setSelectedGoal(goals.find((item) => item.goalPlan === goal));
                                handleChange(e);
                                setFieldValue('requestedChange', newKpiAdjustment.requestedChange || 0);
                                setFieldValue('goal', goal);
                              }}
                            >
                              <option value=""> {values.compSalesId ? '-' : 'Select Time Period'} </option>
                              {goals.map((item, index) => (
                                <option key={index} value={item.goalPlan}>
                                  {item.goalPlan}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="goal" component="div" className="text-danger" />
                          </div>
                          <div className="form-group">
                            <label className="form-label mt-2">
                              <strong>Account Name</strong>
                            </label>
                            <Field name="accountName" className="form-control" type="text" disabled={disable} />
                            <ErrorMessage name="accountName" component="div" className="text-danger" />
                          </div>

                          <div className="form-group">
                            <label className="form-label mt-2">
                              <strong>Great Vines Account URL</strong>
                            </label>
                            <Field name="accountUrl" className="form-control" type="text" disabled={disable} />
                            <ErrorMessage name="accountUrl" component="div" className="text-danger" />
                          </div>
                          <div className="form-group mt-2">
                            <label className="form-label">
                              <strong>Requested Adjustment</strong>
                            </label>
                            <div className="row">
                              <div className="col">
                                <div className="table-responsive table-sm" style={{ fontSize: '12px' }}>
                                  <table className="table table-sm">
                                    <thead>
                                      <tr>
                                        <th>Goal Quantity</th>
                                        <th>Activities</th>
                                        <th>RAD</th>
                                        <th>Requested</th>
                                        <th>% Achieved</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {selectedGoal?.goalQuantity && (
                                        <tr>
                                          <td>{selectedGoal.goalQuantity}</td>
                                          <td>{selectedGoal.activities}</td>
                                          <td>{selectedGoal.rad}</td>
                                          <td>
                                            <Field
                                              name="requestedChange"
                                              className="form-control"
                                              type="text"
                                              style={{ width: '60px' }}
                                              disabled={disable}
                                            />
                                          </td>
                                          <td>
                                            {selectedGoal.percentAchieved}{' '}
                                            {values.requestedChange ? (
                                              <span
                                                style={{ fontWeight: 'bold' }}
                                              >{`-> ${calcNewAchievedPercent(selectedGoal, values.requestedChange)}`}</span>
                                            ) : (
                                              ''
                                            )}{' '}
                                          </td>
                                        </tr>
                                      )}
                                      <ErrorMessage name="requestedChange" component="div" className="text-danger" />
                                    </tbody>
                                  </table>
                                </div>
                                <div className="form-group">
                                  <div className="form-check">
                                    <input
                                      disabled={disable}
                                      name="requestedChange"
                                      onChange={() => {
                                        setFieldValue('adjustmentType', kpiAdjustmentsTypes.Adjustment);
                                      }}
                                      className="form-check-input"
                                      type="radio"
                                      id="formCheck-1"
                                      checked={values.adjustmentType === kpiAdjustmentsTypes.Adjustment}
                                    />
                                    <label className="form-check-label" htmlFor="formCheck-1">
                                      Adjustment
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <input
                                      disabled={disable}
                                      className="form-check-input"
                                      onChange={() => {
                                        setFieldValue('adjustmentType', kpiAdjustmentsTypes.ReportingMistake);
                                      }}
                                      checked={values.adjustmentType === kpiAdjustmentsTypes.ReportingMistake}
                                      type="radio"
                                      id="formCheck-2"
                                    />
                                    <label className="form-check-label" htmlFor="formCheck-2">
                                      Reporting Mistake
                                    </label>
                                  </div>

                                  <div className="form-check mt-2">
                                    <input
                                      disabled={disable}
                                      name="nonClassB"
                                      onChange={() => {
                                        setFieldValue('nonClassB', !values.nonClassB);
                                      }}
                                      className="form-check-input"
                                      type="checkbox"
                                      id="formCheck-1"
                                      checked={values.nonClassB}
                                    />
                                    <label className="form-check-label" htmlFor="formCheck-1">
                                      Non-Class B
                                    </label>
                                  </div>
                                  {values.nonClassB && (
                                    <div>
                                      <label className="form-label">
                                        <strong>Attachments</strong>
                                      </label>
                                      <Field
                                        name="attachments"
                                        component={ImageInput}
                                        disabled={disable}
                                        actions={{
                                          download: !(isKpiRequestForm && !isKpiEditForm),
                                        }}
                                      />
                                      <ErrorMessage name="attachments" component="div" className="text-danger" />
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col">
                          <div className="form-group">
                            <label className="form-label">
                              <strong>Reason for Adjustment</strong>
                            </label>
                            <Field as="textarea" name="reasons" rows={5} className="form-control" disabled={disable} />
                            <ErrorMessage name="reasons" component="div" className="text-danger" />
                          </div>
                          <div className="form-group">
                            <label className="form-label">
                              <strong>Approver Notes</strong>
                            </label>
                            <Field
                              as="textarea"
                              name="approverNotes"
                              rows={5}
                              className="form-control"
                              disabled={isKpiRequestForm}
                            />
                          </div>
                        </div>
                      </div>
                      <div
                        className="my-3"
                        style={{ display: 'inline-flex', float: 'inline-end', flexDirection: 'row-reverse' }}
                      >
                        {!isApprovedRequest && !isKpiEditForm && (
                          <div className="col mx-2">
                            <button className="btn btn-primary" type="submit">
                              {isKpiApproveForm ? 'Approve' : 'Request'}
                            </button>
                          </div>
                        )}
                        {isKpiApproveForm && isApprover && (
                          <div className="col mx-2">
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={(e) => {
                                e.preventDefault();
                                AdminService.RejectApproval({
                                  referenceId: newKpiAdjustment.kpiAdjustmentId,
                                  isProductApproval: false,
                                })
                                  .then(() => {
                                    navigate(`/sales-commission/kpi-adjustment`);
                                    hideLoading();
                                    toast.success('The request has been rejected.');
                                  })
                                  .catch((e) => {
                                    hideLoading();
                                    setError(e.status ? e : { status: 500 });
                                  });
                              }}
                            >
                              Decline
                            </button>
                          </div>
                        )}
                        {((isAdmin && !isKpiRequestForm) || (isKpiEditForm && !isApprovedRequest)) && (
                          <div className="col mx-2">
                            <button
                              className="btn btn-secondary"
                              onClick={(e) => {
                                e.preventDefault();
                                salesCompService
                                  .DeleteKPIAdjustmentRequest(newKpiAdjustment.kpiAdjustmentId)
                                  .then(() => {
                                    navigate(`/sales-commission/kpi-adjustment`);
                                    hideLoading();
                                    toast.success('The request has been rejected.');
                                  })
                                  .catch((e) => {
                                    hideLoading();
                                    setError(e.status ? e : { status: 500 });
                                  });
                              }}
                            >
                              Delete
                            </button>
                          </div>
                        )}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewKpiAdjustmentRequest;
