import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollToTop from 'react-scroll-to-top';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { hideLoading, showLoading } from '../../lib/uiService';
import salesCompService from '../../services/sales.bonus.service';
import useErrorHandling from '../../hooks/useErrorHandling';
import { MarketManagerItem } from '../../components/SalesBonus/MarketManagerItem';
import { RollupManagerItem } from '../../components/SalesBonus/RollupManagerItem';
import { useNavigate, useParams } from 'react-router-dom';
import { formatDate, formatQuarter } from '../../utils/formatDate';
import Select from 'react-select';
import { formatCurrencyFromNum } from '../../utils/formatNum';
import { UserRole } from '../../lib/types';
import { useAuth } from '../../providers/AuthProvider';

export interface Description {
  manager: string;
  userId: any;
  territory?: string;
  inValidUser: boolean;
  totalPotential: string;
  totalEarned: string;
}

const MySalesBonus: React.FC = () => {
  const { role, id } = useAuth();
  const [salesCompList, setSalesCompList] = useState<any[]>();
  const [salesRollUp, setSalesRollUp] = useState<any[]>();
  const [salesCompItemList, setSalesItemList] = useState<any[]>([]);
  const [salesComp, setSalesComp] = useState<any>();
  const { salesCompId } = useParams();
  const navigate = useNavigate();

  const { setError } = useErrorHandling();

  useEffect(() => {
    showLoading();
    const tempSalesCompId = salesCompId ? salesCompId : localStorage.getItem('salesCompId');
    salesCompService
      .GetSalesComp()
      .then(({ data }) => {
        if (data.length > 0) {
          const tempSalesCompList = data.map((comp: any) => {
            const startDate = formatDate(
              { month: '2-digit', day: '2-digit', year: 'numeric' },
              new Date(comp.startDate),
            );
            const endDate = formatDate({ month: '2-digit', day: '2-digit', year: 'numeric' }, new Date(comp.endDate));
            const quarter = comp?.quarter || formatQuarter(comp.startDate);
            const label = `${quarter}: ${startDate ?? ''} - ${endDate ?? ''}`;
            return { label, value: comp.salesCompId };
          });
          setSalesCompList(tempSalesCompList);
          if (salesCompId && salesCompId !== ':salesCompId' && salesCompId !== '#') {
            const tempSalesComp = data.find((comp: any) => comp.salesCompId == tempSalesCompId);
            const startDate = formatDate(
              { month: '2-digit', day: '2-digit', year: 'numeric' },
              new Date(tempSalesComp.startDate),
            );
            const endDate = formatDate(
              { month: '2-digit', day: '2-digit', year: 'numeric' },
              new Date(tempSalesComp.endDate),
            );
            const quarter = tempSalesComp?.quarter || formatQuarter(tempSalesComp.startDate);
            const label = `${quarter}: ${startDate ?? ''} - ${endDate ?? ''}`;

            setSalesComp({ value: tempSalesComp.salesCompId, label });
          }
        }
      })
      .catch((error) => {
        console.error(error);
        setError({ status: error.response.status });
      });
    hideLoading();
  }, []);

  useEffect(() => {
    showLoading();
    if (salesComp?.value) {
      salesCompService
        .GetSalesCompDetail(salesComp?.value)
        .then(({ data }) => {
          if (data) {
            const items = formatSalesComp(data.groups);
            setSalesItemList(items);
          } else {
            setSalesItemList(null);
          }
        })
        .catch((error) => {
          console.error(error);
          setError({ status: error.response.status });
        });

      salesCompService
        .GetSalesRollUp(salesComp?.value)
        .then(({ data }) => {
          if (data.salesCompRollUp.length > 0) {
            const managers = data.salesCompRollUp
              .map(({ managerId }) => managerId)
              .filter((value, index, self) => self.indexOf(value) === index)
              .sort((a, b) => {
                if (a === id) return -1;
                if (b === id) return 1;
                return 0;
              });
            const rollUpData = managers.map((id) => {
              const rollUp = data.salesCompRollUp.find(({ managerId }) => id == managerId);
              const columns = getColumn([
                'Market Manager',
                '% Total Contribution',
                '% Earned Contribution',
                '$ Contribution',
              ]);
              const rows = data.salesCompRollUp
                .filter(({ managerId }) => id == managerId)
                .map((rollUp: any) => ({
                  'Market Manager': rollUp.user.firstName + ' ' + rollUp.user.lastName,
                  '% Total Contribution': rollUp.totalContribution,
                  '% Earned Contribution': rollUp.percentEarned,
                  '$ Contribution': formatCurrencyFromNum(Number(rollUp.compenstation)),
                }));
              const total = {
                'Market Manager': 'Total',
                '% Total Contribution': '',
                '% Earned Contribution': '',
                '$ Contribution': formatCurrencyFromNum(
                  data.salesCompRollUp
                    .filter(({ managerId }) => id == managerId)
                    .reduce((total: number, rollUp: any) => total + Number(rollUp.compenstation), 0),
                ),
              };
              const description = {
                manager: rollUp.manager.firstName + ' ' + rollUp.manager.lastName,
                totalPotential: formatCurrencyFromNum(Number(rollUp.totalPotential)),
                totalEarned: total['$ Contribution'],
                userId: rollUp.userId,
              };
              return { description, columns, rows: rows.concat(total) };
            });
            setSalesRollUp(rollUpData);
          } else {
            setSalesRollUp(null);
          }
          if (data.salesComp) {
            const items = formatSalesComp(data.salesComp.groups);
            setSalesItemList(items);
          }
        })
        .catch((error) => {
          console.error(error);
          setError({ status: error.response.status });
        });
    }
    hideLoading();
  }, [salesComp, setSalesComp]);

  const formatSalesComp = (data) => {
    const response = data.map((group: any) => {
      const header = ['Goal Plan', 'Goal Qty', 'Activities', 'RAD', '% Achieved', 'Compenstation'];
      const totalRowIndex = group.items.findIndex((item: any) => ['Total', 'Total:'].includes(item.goalPlan));
      const rows = group.items.map((item: any) => ({
        'Goal Plan': item.goalPlan,
        'Goal Qty': item.goalQuantity,
        Activities: item.activities,
        RAD: item.rad,
        '% Achieved': item.percentAchieved,
        Compenstation: formatCurrencyFromNum(Number(item.compenstation)),
      }));

      let total;
      if (totalRowIndex !== -1) {
        total = {
          'Goal Plan': 'Total',
          'Goal Qty': rows[totalRowIndex]['Goal Qty'],
          Activities: rows[totalRowIndex].Activities,
          RAD: rows[totalRowIndex].RAD,
          '% Achieved': rows[totalRowIndex]['% Achieved'] + '%',
          Compenstation: rows[totalRowIndex].Compenstation,
        };
      } else {
        const agg = group.items.reduce(
          (acc: any, item: any) => {
            acc['Goal Qty'] += Number(item.goalQuantity);
            acc.Activities += Number(item.activities);
            acc.RAD += Number(item.rad);
            acc.Compenstation += Number(item.compenstation);
            return acc;
          },
          { 'Goal Qty': 0, Activities: 0, RAD: 0, Compenstation: 0 },
        );
        total = {
          'Goal Plan': 'Total',
          'Goal Qty': agg['Goal Qty'],
          Activities: agg.Activities,
          RAD: '',
          '% Achieved': agg['Goal Qty'] === 0 ? 0 : Math.round((agg.Activities / agg['Goal Qty']) * 100) + '%',
          Compenstation: formatCurrencyFromNum(Number(agg.Compenstation)),
        };
      }
      const description = {
        manager: group.user.firstName + ' ' + group.user.lastName,
        territory: group.territory?.name || '',
        totalPotential: formatCurrencyFromNum(Number(group.totalPotential)),
        totalEarned: total.Compenstation,
        userId: group.userId,
      };
      rows.splice(totalRowIndex, 1);
      return { description, header, rows: rows.concat(total) };
    });
    return response;
  };

  const onSelectSaleComp = (salesComp: any) => {
    setSalesComp(salesComp);
    const saleCompId = salesComp.value as string;
    navigate(`/sales-bonus/detail/${saleCompId}`);
  };

  const getColumn = useCallback((header: any[]) => {
    return header.map((val, i) => {
      if (i === header.length - 1) {
        return {
          Header: val,
          id: 'hidden',
          Cell: ({ data, row }: { data: any; row: any }) => (
            <td>
              <span>{data[row.id][val]}</span>
            </td>
          ),
        };
      } else {
        return {
          Header: val,
          id: val,
          Cell: ({ data, row }: { data: any; row: any }) => (
            <td>
              <span>{data[row.id][val]}</span>
            </td>
          ),
        };
      }
    });
  }, []);

  const isAdmin = useMemo(() => {
    return role?.includes(UserRole.Admin) || role?.includes(UserRole.SalesBonusAdmin);
  }, [role]);

  return (
    <>
      <div>
        <h3 className="text-dark mb-4">My Sales Bonus</h3>
        {!salesCompId && !localStorage.getItem('salesCompId') && !salesCompItemList && (
          <div
            className="position-absolute d-flex align-items-center justify-content-center"
            style={{ height: '900px', width: '960px' }}
          >
            <div
              className="me-2 spinner-border text-info"
              style={{
                position: 'relative',
                zIndex: 1,
              }}
            ></div>
          </div>
        )}
        <Card
          style={{
            filter: !salesCompId && !localStorage.getItem('salesCompId') && !salesCompItemList ? 'blur(5px)' : 'none',
            transition: 'filter 0s ease',
          }}
        >
          {!salesCompList ? (
            <div className="h4 text-center py-5">There is no Sales Bonus Data</div>
          ) : (
            <>
              <div className="p-3">
                <h5 className="text-primary">Bonus Breakdown</h5>
                <div className="d-flex justify-content-between mt-4">
                  <div>
                    <h3>Bonus Period:</h3>
                    <div>
                      <Select
                        closeMenuOnSelect={false}
                        value={salesComp}
                        options={salesCompList}
                        onChange={onSelectSaleComp}
                        styles={{
                          container: (baseStyles) => ({
                            ...baseStyles,
                            width: '280px',
                          }),
                          indicatorSeparator: (styles) => ({
                            ...styles,
                            backgroundColor: 'white',
                          }),
                          menu: (baseStyles) => ({
                            ...baseStyles,
                            zIndex: '1000',
                          }),
                        }}
                      />
                    </div>
                  </div>
                  {salesRollUp && (
                    <div className="mr-5">
                      {!isAdmin && (
                        <Button href="#My Sales Bonus" className="mx-1" variant="light">
                          My Sales Bonus
                        </Button>
                      )}
                      <Button href={'#Regional Managers and Director of Sales'} className="mx-1" variant="light">
                        {isAdmin ? 'Regional Managers and Director of Sales' : 'Regional Managers'}
                      </Button>
                      <Button href="#Market Manager" className="mx-1" variant="light">
                        Market Managers
                      </Button>
                    </div>
                  )}
                </div>
                {!salesRollUp && !salesCompItemList && (
                  <div className="h5 text-center py-5">There is no matched Sales Bonus Data</div>
                )}
              </div>
              <div className="px-3">
                {!isAdmin && salesRollUp && (
                  <div id="My Sales Bonus">
                    <h4>My Sales Bonus</h4>
                    {salesRollUp && (
                      <RollupManagerItem
                        description={salesRollUp[0]?.description}
                        columns={salesRollUp[0]?.columns}
                        rows={salesRollUp[0]?.rows}
                        showName={salesRollUp?.length !== 1}
                        checkUser={false}
                      />
                    )}
                  </div>
                )}
                {salesComp && <h4>{isAdmin ? 'Regional Managers and Director of Sales' : 'Regional Managers'}</h4>}
                {salesRollUp &&
                  salesRollUp.map(({ description, columns, rows }, j) => {
                    return isAdmin ? (
                      <div key={j} id={'Regional Managers and Director of Sales'}>
                        <RollupManagerItem
                          description={description}
                          columns={columns}
                          rows={rows}
                          showName={salesRollUp?.length !== 1}
                          checkUser={false}
                        />
                      </div>
                    ) : j !== 0 ? (
                      <div key={j} id={'Regional Managers and Director of Sales'}>
                        <RollupManagerItem
                          description={description}
                          columns={columns}
                          rows={rows}
                          showName={salesRollUp?.length !== 1}
                          checkUser={false}
                        />
                      </div>
                    ) : null;
                  })}
                {salesCompItemList && (
                  <div id="Market Manager">
                    {salesCompItemList.map(({ description, header, rows }, j) => {
                      const columns = getColumn(header);
                      return (
                        <div key={j}>
                          <MarketManagerItem description={description} columns={columns} rows={rows} />
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
              <ScrollToTop
                smooth
                component={<FontAwesomeIcon className="text-white" icon={faAngleUp} />}
                viewBox="0 0 245 245"
                className="bg-secondary"
              />
            </>
          )}
        </Card>
      </div>
    </>
  );
};

export default MySalesBonus;
